<template>
  <div id="login">
    <div class="login-card">
      <div class="card-title">
        <h1>CLINIMETRIAS</h1>
      </div>

      <div class="content">
        <h3>
          Permiso denegado
        </h3>
        <p>Usted no tiene permiso para acceder a esta página</p>

        <button type="button" class="btn btn-primary" @click="toGetBack">
          Regresar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    token: {
      get() {
        return this.$store.state.token.userToken;
      },
    },
  },
  methods: {
    toGetBack() {
      if (this.token) {
        this.$router.push({ name: "Home" });
      } else {
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style scoped>
@charset "UTF-8";
#login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: transparent;
  background-image: url("../../assets/helpharma-login-background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}
#login .login-card {
  background: var(--white-color);
  width: 24rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
#login .login-card .card-title {
  background-color: var(--dark-blue-color);
  padding: 2rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#login .login-card .card-title h1 {
  color: var(--white-color);
  text-align: center;
  font-size: 1.2rem;
}
#login .login-card .content {
  padding: 3rem 2.5rem 5rem;
}
#login .login-card #email,
#login .login-card #password {
  display: block;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 1.75rem;
  padding: 0.25rem 0;
  border: none;
  border-bottom: 1px solid #dbdbdb;
  transition: all 0.5s;
}
#login .login-card #email:hover,
#login .login-card #password:hover {
  border-color: #7a7a7a;
}
#login .login-card #email:active,
#login .login-card #email:focus,
#login .login-card #password:active,
#login .login-card #password:focus {
  border-color: var(--dark-blue-color);
}
#login .login-card .checkbox {
  color: #b5b5b5;
  font-size: 0.8rem;
}
#login .login-card .checkbox span {
  margin-left: 0.5rem;
}
#login .login-card a {
  font-size: 0.8rem;
}
#login .login-card .options {
  color: #b5b5b5;
  margin-bottom: 1.5rem;
}
#login .login-card button {
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--dark-blue-color);
  border-radius: 4rem;
  display: block;
  width: 100%;
  background: transparent;
  border: 2px solid var(--dark-blue-color);
  padding: 0.9rem 0 1.1rem;
  transition: color 0.5s, border-color 0.5s;
}
#login .login-card button:hover,
#login .login-card button:focus {
  border-color: var(--dark-blue-color);
  color: var(--white-color);
  background: var(--dark-blue-color);
}
#login .login-card button:active {
  transform: translateY(1px);
}

label {
  cursor: pointer;
}

.regular-checkbox {
  display: none;
}

.regular-checkbox + label {
  background-color: #fafafa;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 7px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.regular-checkbox:checked + label {
  background-color: #e9ecee;
}

.regular-checkbox:checked + label:after {
  content: "✔";
  font-size: 11px;
  position: absolute;
  top: 0;
  left: 3px;
  color: #b5b5b5;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.register-option {
  color: #7a7a7a;
}

.level-right {
  color: var(--dark-blue-color);
}

.register-option span {
  color: var(--dark-blue-color);
}

@media screen and (max-width: 768px) {
  #login .login-card .options {
    display: grid;
  }
}
/* Made by: Paul Barker */
/* https://codepen.io/paulhbarker/pen/mwOREz */
</style>
